<template>
  <div class="container">
    <div class="input-wrapper">
      <el-input
        v-model="search"
        placeholder="输入用户名或手机号"
        class="input-box"
        clearable
      ></el-input>
    </div>
    <div class="button-wrapper">
      <el-button type="primary" @click="fetchUsers" class="search-button" icon="el-icon-search">查询</el-button>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { ElMessageBox } from 'element-plus';

export default {
  data() {
    return {
      search: '',
      users: [],
      searched: false
    };
  },
  methods: {
    async fetchUsers() {
      this.searched = false; // 重置搜索状态
      if (this.search.trim()) {
        try {
          const response = await axios.get('http://api.chisong.cc:8088/test.php', {
            params: { search: this.search }
          });
          this.users = response.data;
          this.searched = true;
         if (this.users.length > 0) {
              ElMessageBox.alert(
                `用户名：${this.users[0].username || '未知'}，手机号：${this.users[0].mobile || '未知'}`,
                '查询结果'
              );
} else {
               ElMessageBox.alert('未找到用户信息', '提示');
}
        } catch (error) {
          console.error('Error:', error);
          this.searched = true;
         
        }
      } else {
        ElMessageBox.alert('未找到用户信息', '提示');
      }
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-wrapper {
  margin-bottom: 10px;
}

.input-box {
  width: 200px; /* 调整输入框的宽度 */
}

.button-wrapper {
  margin-top: 10px;
}
</style>



